<template>
	<v-container fluid class="pa-0">
		<v-container fluid class="py-0">
			<!-- Overview header -->
			<v-card flat>
				<v-card-title>
					<v-col cols="auto">
						<h2 class="display-3 font-weight-medium">Secrets</h2>
					</v-col>
					<v-spacer></v-spacer>
				</v-card-title>
				<v-alert v-if="error" tile type="error" class="mb-0">{{
					error
				}}</v-alert>
			</v-card>
		</v-container>

		<v-card flat color="grey lighten-4" min-height="76vh">
			<v-container fluid class="pa-5">
				<!-- filter -->
				<v-card class="mt-0 py-5" elevation="3">
					<v-row align="center" class="px-5">
						<v-col cols="auto">
							<v-autocomplete
								v-model="selectedSubscription"
								:items="subscriptions"
								item-text="displayName"
								item-value="subscriptionId"
								label="Subscription"
								hide-details
								dense
								color="primary"
								class="subtitle-2"
								:loading="isLoadingSubscriptions"
							></v-autocomplete>
						</v-col>
						<v-col cols="auto">
							<v-autocomplete
								v-model="selectedVault"
								:items="vaults"
								item-text="name"
								item-value="properties.vaultUri"
								label="Vault"
								hide-details
								dense
								color="primary"
								class="subtitle-2"
								:loading="isLoadingVaults"
							></v-autocomplete>
						</v-col>
						<v-col cols="auto">
							<v-text-field
								v-model="searchSecret"
								ref="searchSecret"
								label="Search | Ctrl+;"
								hide-details
								dense
								class="subtitle-2"
								clearable
								@click:clear="clearSearchSecret()"
							>
								></v-text-field
							>
						</v-col>
						<v-spacer />
						<v-col cols="auto">
							<v-tooltip
								bottom
								v-if="
									defaultSelection.subscriptionId ===
										selectedSubscription &&
									defaultSelection.vault === selectedVault &&
									defaultSelection.subscriptionId != null
								"
							>
								<template v-slot:activator="{ on }">
									<v-btn
										v-on="on"
										icon
										color="primary"
										@click="deleteDefaultSelection"
										><v-icon>star</v-icon></v-btn
									>
								</template>
								<span> Delete Default </span>
							</v-tooltip>
							<v-tooltip bottom v-else>
								<template v-slot:activator="{ on }">
									<v-btn
										v-on="on"
										icon
										color="primary"
										@click="setDefaultSelection"
										><v-icon>star_border</v-icon></v-btn
									>
								</template>
								<span> Set Default </span>
							</v-tooltip>
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<v-btn
										v-on="on"
										:disabled="!selectedVault.length"
										small
										rounded
										color="primary"
										@click="addSecret"
										>Add</v-btn
									>
								</template>
								<span> Add Secret | Ctrl+, </span>
							</v-tooltip>
						</v-col>
					</v-row>
				</v-card>

				<!-- data table card -->
				<v-card class="mt-3" elevation="3">
					<v-card-text class="pb-3">
						<v-data-table
							:headers="secretHeaders"
							:items="secrets"
							:items-per-page="25"
							:footer-props="{
								'items-per-page-options': [10, 25, 50, 75, 100],
							}"
							:sort-by="['secretName']"
							:search="searchSecret"
							v-model="selectedSecret"
							show-select
							single-select
							item-key="secretId"
							dense
							show-expand
							single-expand
							:loading="isLoadingSecrets"
							no-data-text="Please select a subscription and a vault"
						>
							<template v-slot:[`item.username`]="{ item }">
								<template>
									<v-btn
										v-if="item.username"
										icon
										color="primary"
										small
										@click="copyValue(item.username)"
									>
										<v-icon small>content_copy</v-icon>
									</v-btn>
									{{ item.username }}
								</template>
							</template>

							<template v-slot:[`item.secretValue`]="{ item }">
								<template v-if="item.showSecretValue">
									<v-btn
										icon
										color="primary"
										small
										@click="copyValue(item.secretValue)"
									>
										<v-icon small>content_copy</v-icon>
									</v-btn>
									<v-btn
										icon
										color="primary"
										small
										@click="
											item.showSecretValue =
												!item.showSecretValue
										"
									>
										<v-icon small>visibility_off</v-icon>
									</v-btn>
									{{ item.secretValue }}
								</template>
								<template v-else>
									<v-btn
										icon
										color="primary"
										small
										@click="copyValue(item.secretValue)"
									>
										<v-icon small>content_copy</v-icon>
									</v-btn>
									<v-btn
										icon
										color="primary"
										small
										@click="
											item.showSecretValue =
												!item.showSecretValue
										"
									>
										<v-icon small>visibility</v-icon>
									</v-btn>
									********
								</template>
							</template>

							<template v-slot:[`item.url`]="{ item }">
								<template v-if="item.url">
									<v-btn
										icon
										color="primary"
										@click="copyValue(item.url)"
										small
									>
										<v-icon small>content_copy</v-icon>
									</v-btn>
									<span
										v-if="item.url.length <= 30"
										class="row-pointer"
										@click="openUrl(item.url)"
										>{{ item.url }}</span
									>
									<span
										v-else
										class="row-pointer"
										@click="openUrl(item.url)"
										>{{
											item.url.substring(0, 30) + ".."
										}}</span
									>
								</template>
							</template>

							<template v-slot:[`item.action`]="{ item }">
								<v-tooltip bottom>
									<template v-slot:activator="{ on }">
										<v-btn
											v-on="on"
											icon
											color="primary"
											small
											@click="editSecret(item)"
										>
											<v-icon small>edit</v-icon>
										</v-btn>
									</template>
									<span> Edit Secret </span>
								</v-tooltip>
								<v-tooltip bottom>
									<template v-slot:activator="{ on }">
										<v-btn
											v-on="on"
											icon
											color="primary"
											small
											@click="deletingSecret(item)"
										>
											<v-icon small>delete</v-icon>
										</v-btn>
									</template>
									<span> Delete Secret </span>
								</v-tooltip>
								<v-tooltip bottom>
									<template v-slot:activator="{ on }">
										<v-btn
											v-on="on"
											icon
											color="primary"
											small
											@click="createOneTimeSecret(item)"
										>
											<v-icon small>vpn_key</v-icon>
										</v-btn>
									</template>
									<span> Create One Time Secret </span>
								</v-tooltip>
							</template>

							<template v-slot:expanded-item="{ headers, item }">
								<td :colspan="headers.length" class="px-5 py-5">
									<v-row class="align-center ml-0 pl-0 mb-0">
										<v-col cols="auto">
											<h2>Tags</h2>
										</v-col>
										<v-spacer />
									</v-row>
									<v-divider class="mb-2"></v-divider>
									<v-row dense>
										<v-col cols="6" sm="4" md="2"
											><strong>Notes</strong></v-col
										>
										<v-col cols="6" sm="8" md="10">
											{{ item.notes }}
										</v-col>
									</v-row>
									<template v-for="tag in item.tags">
										<v-row :key="tag.key" dense>
											<v-col cols="6" sm="4" md="2"
												><strong>{{
													tag.key
												}}</strong></v-col
											>
											<v-col cols="6" sm="8" md="10">
												{{ tag.value }}
											</v-col>
										</v-row>
									</template>
								</td>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-container>
		</v-card>

		<DeleteDialog
			:dialog.sync="deleteSecretDialog"
			:item="deletedSecret"
			:deleteError="deleteError"
			v-on:confirmed="deleteSecret"
		>
			<template v-slot:title>Delete - Secret</template>
			<template v-slot:content
				>Are you sure you want to delete this secret?</template
			>
		</DeleteDialog>

		<SecretForm
			:dialog.sync="secretDialog"
			:object.sync="editedSecret"
			:selectedVault.sync="selectedVault"
			v-on:done="getSecrets(selectedVault)"
		></SecretForm>

		<OneTimeSecretForm
			:dialog.sync="oneTimeSecretDialog"
			:object.sync="editedSecret"
		></OneTimeSecretForm>
	</v-container>
</template>

<style scoped>
.row-pointer {
	cursor: pointer;
}
</style>

<script>
import helper from "@/utils/helper.js";
import msalAuth from "@/utils/msalAuth.js";
import axios from "axios";
import DeleteDialog from "@/components/base/DeleteDialog.vue";
import SecretForm from "@/components/secrets/SecretForm.vue";
import OneTimeSecretForm from "@/components/secrets/OneTimeSecretForm.vue";

export default {
	name: "Login",
	components: { DeleteDialog, SecretForm, OneTimeSecretForm },

	data: () => ({
		error: "",
		deleteError: "",

		selectedSubscription: [],
		selectedVault: [],
		selectedSecret: [],
		searchSecret: "",
		defaultSelection: {
			subscriptionId: null,
			vault: null,
		},

		isLoadingSubscriptions: false,
		isLoadingVaults: false,
		isLoadingSecrets: false,

		subscriptions: [],
		vaults: [],
		secrets: [],

		secretDialog: false,
		deleteSecretDialog: false,
		editedSecret: null,
		deletedSecret: null,

		oneTimeSecretDialog: false,

		secretHeaders: [
			{
				text: "Secret Name",
				value: "secretName",
				sortable: true,
			},
			{
				text: "Type",
				value: "type",
				sortable: true,
			},
			{
				text: "Url | Ctrl+[",
				value: "url",
				sortable: false,
			},
			{
				text: "Username | Ctrl+]",
				value: "username",
				sortable: true,
			},
			{
				text: "Secret Value | Ctrl+\\",
				value: "secretValue",
				sortable: false,
			},
			{
				text: "Actions",
				align: "center",
				value: "action",
				sortable: false,
			},
			{ text: "", value: "data-table-expand" },
		],

		errors: [],
		rules: {
			required: (v) => !helper.isEmpty(v) || "This field is required",
			listRequired: (v) =>
				(v || "").length >= 1 || `This field is required`,
			minLength: (len) => (v) =>
				(v || "").length >= len || `Require at least ${len}`,
			maxLength: (len) => (v) => (v || "").length <= len || "Too long",
		},
	}),

	async created() {
		this.getDefaultSelection();
		this.getSearchSecret();
		this.getSubscriptions();
	},

	watch: {
		selectedSubscription(val) {
			if (val) {
				// console.log(val);
				this.getVaults(val);
			}
		},

		selectedVault(val) {
			if (val) {
				this.searchSecret = "";
				this.getSecrets(val);
			}
		},

		searchSecret(val) {
			if (val) {
				this.setSearchSecret(val);
			} else {
				this.clearSearchSecret();
			}
		},
	},

	mounted() {
		this._keyListener = function (e) {
			// Search focus
			if (e.key === ";" && (e.ctrlKey || e.metaKey)) {
				e.preventDefault(); // present "Save Page" from getting triggered.
				this.focusSearchSecret();
			}

			// Copy url
			if (e.key === "[" && (e.ctrlKey || e.metaKey)) {
				e.preventDefault(); // present "Save Page" from getting triggered.
				this.copyValue(this.selectedSecret[0].url);
			}

			// Copy username
			if (e.key === "]" && (e.ctrlKey || e.metaKey)) {
				e.preventDefault(); // present "Save Page" from getting triggered.
				this.copyValue(this.selectedSecret[0].username);
			}

			// Copy password
			if (e.key === "\\" && (e.ctrlKey || e.metaKey)) {
				e.preventDefault(); // present "Save Page" from getting triggered.
				this.copyValue(this.selectedSecret[0].secretValue);
			}

			// Add secret
			if (e.key === "," && (e.ctrlKey || e.metaKey)) {
				e.preventDefault(); // present "Save Page" from getting triggered.
				this.addSecret();
			}
		};

		document.addEventListener("keydown", this._keyListener.bind(this));
	},

	beforeDestroy() {
		document.removeEventListener("keydown", this._keyListener);
	},

	methods: {
		async getManagementAccessToken() {
			let scopes = [
				"https://management.core.windows.net/user_impersonation",
			];
			try {
				let token = await msalAuth.acquireToken(scopes);
				return token;
			} catch (err) {
				console.log(err);
				this.error = err.toString();
			}
		},

		async getVaultAccessToken() {
			let scopes = ["https://vault.azure.net/user_impersonation"];
			try {
				let token = await msalAuth.acquireToken(scopes);
				return token;
			} catch (err) {
				console.log(err);
				this.error = err.toString();
			}
		},

		async getSubscriptions() {
			let managementAccessToken = await this.getManagementAccessToken();
			this.isLoadingSubscriptions = true;

			axios
				.post(
					`${process.env.VUE_APP_APIURL}azure/management/subscriptions`,
					{
						accessToken: managementAccessToken,
					}
				)
				.then((response) => {
					// console.log(response);
					this.subscriptions = response.data.value;
				})
				.catch((err) => {
					console.log(err.response);
					this.error =
						this.error +
						err +
						" | Get Subscriptions | " +
						err.response.data.message;
				})
				.finally(() => {
					this.isLoadingSubscriptions = false;

					// set default value based on local storage
					if (this.defaultSelection.subscriptionId) {
						this.selectedSubscription =
							this.defaultSelection.subscriptionId;
					}
				});
		},

		async getVaults(subscriptionId) {
			let managementAccessToken = await this.getManagementAccessToken();
			this.isLoadingVaults = true;

			axios
				.post(`${process.env.VUE_APP_APIURL}azure/management/vaults`, {
					accessToken: managementAccessToken,
					subscriptionId: subscriptionId,
				})
				.then((response) => {
					// console.log(response);
					this.vaults = response.data.value;
				})
				.catch((err) => {
					console.log(err.response);
					this.error =
						this.error +
						err +
						" | Get Vaults | " +
						err.response.data.message;
				})
				.finally(() => {
					this.isLoadingVaults = false;

					// set default value based on local storage
					if (this.defaultSelection.vault) {
						this.selectedVault = this.defaultSelection.vault;
					}
				});
		},

		async getSecrets(vaultUri) {
			let vaultAccessToken = await this.getVaultAccessToken();
			this.isLoadingSecrets = true;

			axios
				.post(`${process.env.VUE_APP_APIURL}azure/vault/secrets`, {
					accessToken: vaultAccessToken,
					vaultUri: vaultUri,
				})
				.then((response) => {
					// console.log(response);
					this.secrets = response.data.value;
				})
				.catch((err) => {
					console.log(err.response);
					this.error =
						this.error +
						err +
						" | Get Secrets | " +
						err.response.data.message;
				})
				.finally(() => {
					this.isLoadingSecrets = false;
				});
		},

		showSecretValue(item) {
			item.showSecretValue = !item.showSecretValue;
		},

		async copyValue(value) {
			try {
				await navigator.clipboard.writeText(value);
				// show snackbar
				const payload = {
					color: "success",
					message: "Copied value",
				};
				this.$store.dispatch("snackbar/showMessage", payload);
			} catch ($e) {
				// show snackbar
				const payload = {
					color: "error",
					message: "Error copying value",
				};
				this.$store.dispatch("snackbar/showMessage", payload);
			}
		},

		focusSearchSecret() {
			this.$refs["searchSecret"].focus();
		},

		openUrl(url) {
			window.open(url, "_blank");
		},

		addSecret() {
			this.editedSecret = null;
			this.secretDialog = true;
		},

		editSecret(secret) {
			this.editedSecret = JSON.parse(JSON.stringify(secret));
			this.secretDialog = true;
		},

		deletingSecret(item) {
			this.deletedSecret = item;
			this.deleteSecretDialog = true;
		},

		async deleteSecret(item) {
			let vaultAccessToken = await this.getVaultAccessToken();

			axios
				.post(
					`${process.env.VUE_APP_APIURL}azure/vault/secret/delete`,
					{
						accessToken: vaultAccessToken,
						vaultUri: this.selectedVault,
						secretName: item.secretName,
					}
				)
				.then((response) => {
					console.log(response);
					this.deleteSecretDialog = false;
					this.getSecrets(this.selectedVault);

					// show snackbar
					const payload = {
						color: "success",
						message: "Deleted Secret",
					};
					this.$store.dispatch("snackbar/showMessage", payload);
				})
				.catch((err) => {
					console.log(err.response);
					this.deleteError =
						this.deleteError +
						err +
						" | Delete Secret | " +
						err.response.data.message;
				})
				.finally(() => {});
		},

		createOneTimeSecret(secret) {
			this.editedSecret = JSON.parse(JSON.stringify(secret));
			this.oneTimeSecretDialog = true;
		},

		getDefaultSelection() {
			const defaultSelection = JSON.parse(
				localStorage.getItem("secrets/defaultSelection")
			);

			if (defaultSelection) {
				this.defaultSelection = defaultSelection;
			}
		},

		setDefaultSelection() {
			this.defaultSelection.subscriptionId = this.selectedSubscription;
			this.defaultSelection.vault = this.selectedVault;
			localStorage.setItem(
				"secrets/defaultSelection",
				JSON.stringify(this.defaultSelection)
			);
		},

		deleteDefaultSelection() {
			localStorage.removeItem("secrets/defaultSelection");
			this.defaultSelection = {
				subscriptionId: null,
				vault: null,
			};
		},

		getSearchSecret() {
			const searchSecret = localStorage.getItem("secrets/searchSecret");

			if (searchSecret) {
				this.searchSecret = searchSecret;
			}
		},

		setSearchSecret(val) {
			if (val) {
				localStorage.setItem("secrets/searchSecret", val);
			} else {
				localStorage.removeItem("secrets/searchSecret");
			}
		},

		clearSearchSecret() {
			localStorage.removeItem("secrets/searchSecret");
		},
	},

	computed: {},
};
</script>
