import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure you are using css-loader
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: "#10325a", // #Greenie Blauw: 4c49f6
				secondary: colors.cyan.lighten4, // #673AB7
				success: colors.green.lighten1, //#66BB6A
				info: colors.lightBlue.lighten1, //#29B6F6
				accent: colors.indigo.lighten1, // #5C6BC0
				warning: colors.orange,
				error: colors.red.lighten1, // #EF5350
			},
		},
	},
	icons: {
		iconfont: "md",
	},
});
