<template>
	<!-- Edit secret -->
	<v-dialog
		v-model="dialog"
		persistent
		max-width="800px"
		scrollable
		@keydown.esc="closeOneTimeSecretDialog"
	>
		<v-card>
			<v-card-title class="primary white--text">
				{{ formTitle }}
				<v-spacer></v-spacer>
			</v-card-title>
			<v-alert v-if="error" tile type="error" class="mb-0">{{
				error
			}}</v-alert>

			<v-card-text class="pt-10">
				<v-form v-if="!oneTimeSecretLink" v-model="valid" ref="form">
					<v-row>
						<v-col cols="10">
							<v-textarea
								v-model="oneTimeSecretText"
								:rules="[rules.maxLength(1020)]"
								label="One Time Secret"
								outlined
								class="subtitle-2"
								:counter="1020"
							></v-textarea>
						</v-col>
						<v-col cols="2">
							<v-text-field
								v-model="lifetimeSec"
								label="Life Time (sec)"
								outlined
								class="subtitle-2"
								type="number"
							/>
						</v-col>
					</v-row>
				</v-form>
				<v-row v-if="oneTimeSecretLink">
					<v-col cols="12">
						<v-text-field
							v-model="oneTimeSecretLink"
							label="One Time Secret"
							outlined
							class="subtitle-2"
							readonly
						>
							<template v-slot:append>
								<v-icon
									color="primary"
									tabindex="-1"
									@click="copyValue(oneTimeSecretLink)"
									>content_copy</v-icon
								>
							</template></v-text-field
						>
					</v-col>
				</v-row>
			</v-card-text>

			<v-card-actions class="pb-5 pr-5">
				<v-spacer></v-spacer>
				<v-btn
					:disabled="isSaving"
					text
					rounded
					@click="closeOneTimeSecretDialog"
					>Cancel</v-btn
				>
				<v-btn
					v-if="!oneTimeSecretLink"
					outlined
					rounded
					:loading="isSaving"
					color="primary"
					@click="saveOneTimeSecret"
					>Save</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import helper from "@/utils/helper.js";
import axios from "axios";

export default {
	name: "one-time-secret-form",
	props: ["dialog", "object"],
	data() {
		return {
			valid: false,
			error: "",
			isSaving: false,
			isLoadingOneTimeSecret: false,

			secretName: null,
			secretValue: null,
			oneTimeSecretText: null,
			lifetimeSec: 86400,
			oneTimeSecretLink: null,

			rules: {
				required: (v) => !helper.isEmpty(v) || "Dit veld is verplicht",
				minLength: (len) => (v) =>
					(v || "").length >= len ||
					`Invalid character length, required ${len}`,
				maxLength: (len) => (v) =>
					(v || "").length <= len || "Too long",
			},
		};
	},
	computed: {
		isNew() {
			return this.object ? false : true;
		},
		formTitle() {
			return this.isNew
				? "Create One Time Secret"
				: "Create One Time Secret";
		},
	},
	watch: {
		// reset form when dialog open or close
		dialog() {
			this.resetForm();
		},
	},
	created() {},
	methods: {
		resetForm() {
			// reset form state
			this.error = null;
			this.isSaving = false;
			if (this.$refs.form) {
				this.$refs.form.resetValidation();
			}

			// when editing, set form with object
			if (this.dialog && this.object) {
				this.secretName = this.object.secretName;
				this.secretValue = this.object.secretValue;
				this.oneTimeSecretText =
					this.object.secretName +
					"\n\nSecret: " +
					this.object.secretValue;

				if (this.object.username) {
					this.oneTimeSecretText =
						this.oneTimeSecretText +
						"\nUsername: " +
						this.object.username;
				}

				if (this.object.url) {
					this.oneTimeSecretText =
						this.oneTimeSecretText + "\nUrl: " + this.object.url;
				}

				if (this.object.type) {
					this.oneTimeSecretText =
						this.oneTimeSecretText + "\nType: " + this.object.type;
				}
			}
			// when new or closing, set form with default
			else {
				this.secretName = null;
				this.secretValue = null;
				this.oneTimeSecretText = null;
				this.lifetimeSec = 86400;
				this.oneTimeSecretLink = null;
			}
		},

		closeOneTimeSecretDialog() {
			this.$emit("update:dialog", false);
			this.resetForm();
		},

		saveOneTimeSecret() {
			if (!this.$refs.form.validate()) {
				return;
			}

			// set form state
			this.error = null;
			this.isLoadingOneTimeSecret = true;

			axios
				.post(
					`${process.env.VUE_APP_APIURL}onetimesecret/secret/create`,
					{
						oneTimeSecretText: encodeURIComponent(
							this.oneTimeSecretText
						),
						lifetimeSec: this.lifetimeSec,
					}
				)
				.then((response) => {
					this.oneTimeSecretLink =
						"https://onetimesecret.com/secret/" +
						response.data.secret_key;

					// show snackbar
					const payload = {
						color: "success",
						message: "Created One Time Secret",
					};
					this.$store.dispatch("snackbar/showMessage", payload);
				})
				.catch((err) => {
					console.log(err.response);
					this.error =
						this.error +
						err +
						" | Create One Time Secret | " +
						err.response.data.message;
				})
				.finally(() => {
					this.isLoadingOneTimeSecret = false;
				});
		},

		async copyValue(value) {
			try {
				await navigator.clipboard.writeText(value);
				// show snackbar
				const payload = {
					color: "success",
					message: "Copied value",
				};
				this.$store.dispatch("snackbar/showMessage", payload);
			} catch ($e) {
				// show snackbar
				const payload = {
					color: "error",
					message: "Error copying value",
				};
				this.$store.dispatch("snackbar/showMessage", payload);
			}
		},
	},
};
</script>
