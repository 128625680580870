<template>
	<v-app>
		<Snackbar></Snackbar>

		<v-app-bar v-if="isLoggedIn" app color="primary" dark>
			<div class="d-flex align-center">
				<v-img
					alt="Vuetify Logo"
					class="shrink mr-2"
					contain
					:src="require('./assets/linkthings_logo.svg')"
					transition="scale-transition"
					width="40"
				/>
			</div>

			<v-spacer></v-spacer>

			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						v-bind="attrs"
						v-on="on"
						icon
						class="mr-2"
						target="_blank"
						rel="noopener noreferrer"
						@click="logout"
						><v-icon>logout</v-icon></v-btn
					>
				</template>
				<span>Logout</span>
			</v-tooltip>
		</v-app-bar>

		<v-main>
			<router-view />
		</v-main>
	</v-app>
</template>

<script>
import Snackbar from "@/components/base/Snackbar.vue";
import msalAuth from "@/utils/msalAuth.js";

export default {
	name: "App",
	components: {
		Snackbar,
	},

	data: () => ({}),

	created() {
		this.setupMsalAuth();
	},

	computed: {
		isLoggedIn() {
			return this.$store.state.user.isLoggedIn;
		},
	},

	methods: {
		setupMsalAuth() {
			// Basic setup of MSAL helper with client id, or give up
			if (process.env.VUE_APP_MSAL_CLIENT_ID) {
				msalAuth.configure(process.env.VUE_APP_MSAL_CLIENT_ID, false);

				// Restore any cached or saved local user
				this.msalUser = msalAuth.user();
				// console.log(`configured ${msalAuth.isConfigured()}`);
			} else {
				this.error =
					"VUE_APP_MSAL_CLIENT_ID is not set, the app will not function!";
			}
		},

		// Full logout local & server side
		async logout() {
			try {
				await msalAuth.logout();
				this.$store.commit("user/setIsLoggedOut");
				this.$router.push("/login");
			} catch (err) {
				console.log(err);
				this.error = err.toString();
			}
		},
	},
};
</script>
