var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-col',{attrs:{"cols":"auto"}},[_c('h2',{staticClass:"display-3 font-weight-medium"},[_vm._v("Secrets")])]),_c('v-spacer')],1),(_vm.error)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1)],1),_c('v-card',{attrs:{"flat":"","color":"grey lighten-4","min-height":"76vh"}},[_c('v-container',{staticClass:"pa-5",attrs:{"fluid":""}},[_c('v-card',{staticClass:"mt-0 py-5",attrs:{"elevation":"3"}},[_c('v-row',{staticClass:"px-5",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-autocomplete',{staticClass:"subtitle-2",attrs:{"items":_vm.subscriptions,"item-text":"displayName","item-value":"subscriptionId","label":"Subscription","hide-details":"","dense":"","color":"primary","loading":_vm.isLoadingSubscriptions},model:{value:(_vm.selectedSubscription),callback:function ($$v) {_vm.selectedSubscription=$$v},expression:"selectedSubscription"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-autocomplete',{staticClass:"subtitle-2",attrs:{"items":_vm.vaults,"item-text":"name","item-value":"properties.vaultUri","label":"Vault","hide-details":"","dense":"","color":"primary","loading":_vm.isLoadingVaults},model:{value:(_vm.selectedVault),callback:function ($$v) {_vm.selectedVault=$$v},expression:"selectedVault"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{ref:"searchSecret",staticClass:"subtitle-2",attrs:{"label":"Search | Ctrl+;","hide-details":"","dense":"","clearable":""},on:{"click:clear":function($event){return _vm.clearSearchSecret()}},model:{value:(_vm.searchSecret),callback:function ($$v) {_vm.searchSecret=$$v},expression:"searchSecret"}},[_vm._v(" >")])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[(
								_vm.defaultSelection.subscriptionId ===
									_vm.selectedSubscription &&
								_vm.defaultSelection.vault === _vm.selectedVault &&
								_vm.defaultSelection.subscriptionId != null
							)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":_vm.deleteDefaultSelection}},on),[_c('v-icon',[_vm._v("star")])],1)]}}],null,false,1766097710)},[_c('span',[_vm._v(" Delete Default ")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":_vm.setDefaultSelection}},on),[_c('v-icon',[_vm._v("star_border")])],1)]}}])},[_c('span',[_vm._v(" Set Default ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.selectedVault.length,"small":"","rounded":"","color":"primary"},on:{"click":_vm.addSecret}},on),[_vm._v("Add")])]}}])},[_c('span',[_vm._v(" Add Secret | Ctrl+, ")])])],1)],1)],1),_c('v-card',{staticClass:"mt-3",attrs:{"elevation":"3"}},[_c('v-card-text',{staticClass:"pb-3"},[_c('v-data-table',{attrs:{"headers":_vm.secretHeaders,"items":_vm.secrets,"items-per-page":25,"footer-props":{
							'items-per-page-options': [10, 25, 50, 75, 100],
						},"sort-by":['secretName'],"search":_vm.searchSecret,"show-select":"","single-select":"","item-key":"secretId","dense":"","show-expand":"","single-expand":"","loading":_vm.isLoadingSecrets,"no-data-text":"Please select a subscription and a vault"},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
						var item = ref.item;
return [[(item.username)?_c('v-btn',{attrs:{"icon":"","color":"primary","small":""},on:{"click":function($event){return _vm.copyValue(item.username)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("content_copy")])],1):_vm._e(),_vm._v(" "+_vm._s(item.username)+" ")]]}},{key:"item.secretValue",fn:function(ref){
						var item = ref.item;
return [(item.showSecretValue)?[_c('v-btn',{attrs:{"icon":"","color":"primary","small":""},on:{"click":function($event){return _vm.copyValue(item.secretValue)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("content_copy")])],1),_c('v-btn',{attrs:{"icon":"","color":"primary","small":""},on:{"click":function($event){item.showSecretValue =
											!item.showSecretValue}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("visibility_off")])],1),_vm._v(" "+_vm._s(item.secretValue)+" ")]:[_c('v-btn',{attrs:{"icon":"","color":"primary","small":""},on:{"click":function($event){return _vm.copyValue(item.secretValue)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("content_copy")])],1),_c('v-btn',{attrs:{"icon":"","color":"primary","small":""},on:{"click":function($event){item.showSecretValue =
											!item.showSecretValue}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("visibility")])],1),_vm._v(" ******** ")]]}},{key:"item.url",fn:function(ref){
											var item = ref.item;
return [(item.url)?[_c('v-btn',{attrs:{"icon":"","color":"primary","small":""},on:{"click":function($event){return _vm.copyValue(item.url)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("content_copy")])],1),(item.url.length <= 30)?_c('span',{staticClass:"row-pointer",on:{"click":function($event){return _vm.openUrl(item.url)}}},[_vm._v(_vm._s(item.url))]):_c('span',{staticClass:"row-pointer",on:{"click":function($event){return _vm.openUrl(item.url)}}},[_vm._v(_vm._s(item.url.substring(0, 30) + ".."))])]:_vm._e()]}},{key:"item.action",fn:function(ref){
											var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
											var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary","small":""},on:{"click":function($event){return _vm.editSecret(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v(" Edit Secret ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
											var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary","small":""},on:{"click":function($event){return _vm.deletingSecret(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v(" Delete Secret ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
											var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary","small":""},on:{"click":function($event){return _vm.createOneTimeSecret(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("vpn_key")])],1)]}}],null,true)},[_c('span',[_vm._v(" Create One Time Secret ")])])]}},{key:"expanded-item",fn:function(ref){
											var headers = ref.headers;
											var item = ref.item;
return [_c('td',{staticClass:"px-5 py-5",attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"align-center ml-0 pl-0 mb-0"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('h2',[_vm._v("Tags")])]),_c('v-spacer')],1),_c('v-divider',{staticClass:"mb-2"}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6","sm":"4","md":"2"}},[_c('strong',[_vm._v("Notes")])]),_c('v-col',{attrs:{"cols":"6","sm":"8","md":"10"}},[_vm._v(" "+_vm._s(item.notes)+" ")])],1),_vm._l((item.tags),function(tag){return [_c('v-row',{key:tag.key,attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6","sm":"4","md":"2"}},[_c('strong',[_vm._v(_vm._s(tag.key))])]),_c('v-col',{attrs:{"cols":"6","sm":"8","md":"10"}},[_vm._v(" "+_vm._s(tag.value)+" ")])],1)]})],2)]}}],null,true),model:{value:(_vm.selectedSecret),callback:function ($$v) {_vm.selectedSecret=$$v},expression:"selectedSecret"}})],1)],1)],1)],1),_c('DeleteDialog',{attrs:{"dialog":_vm.deleteSecretDialog,"item":_vm.deletedSecret,"deleteError":_vm.deleteError},on:{"update:dialog":function($event){_vm.deleteSecretDialog=$event},"confirmed":_vm.deleteSecret},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Delete - Secret")]},proxy:true},{key:"content",fn:function(){return [_vm._v("Are you sure you want to delete this secret?")]},proxy:true}])}),_c('SecretForm',{attrs:{"dialog":_vm.secretDialog,"object":_vm.editedSecret,"selectedVault":_vm.selectedVault},on:{"update:dialog":function($event){_vm.secretDialog=$event},"update:object":function($event){_vm.editedSecret=$event},"update:selectedVault":function($event){_vm.selectedVault=$event},"update:selected-vault":function($event){_vm.selectedVault=$event},"done":function($event){return _vm.getSecrets(_vm.selectedVault)}}}),_c('OneTimeSecretForm',{attrs:{"dialog":_vm.oneTimeSecretDialog,"object":_vm.editedSecret},on:{"update:dialog":function($event){_vm.oneTimeSecretDialog=$event},"update:object":function($event){_vm.editedSecret=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }