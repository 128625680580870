import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Secrets from "../views/Secrets.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/login",
		name: "Login",
		component: Login,
	},
	{
		path: "/",
		name: "Home",
		component: Home,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/secrets",
		name: "Secrets",
		component: Secrets,
		meta: {
			requiresAuth: true,
		},
	},
];

const router = new VueRouter({
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (sessionStorage.getItem("isLoggedIn")) {
			next();
		} else {
			// next({ name: "Login", query: { redirect: to.path } });
			next({ name: "Login", query: { redirect: "/secrets" } });
		}
	} else {
		next();
	}
});

export default router;
