<template>
	<v-container class="fill-height" fluid style="height: 100vh">
		<!-- Login Dialog -->
		<v-row class="fill-height" style="height: 100vh">
			<v-col sm="12" md="4">
				<v-card class="elevation-0" style="height: 40vh">
					<v-card-title>
						<h2 class="font-weight-black primary--text">
							LinkThings&nbsp;
						</h2>
						<h2 class="font-weight-black warning--text">
							Key Vault
						</h2>
					</v-card-title>
					<v-card-text>
						Secure all your secrets with LinkThings Key Vault! This
						Azure Key Vault integration provides a nice UI on top of
						the secure Azure service.
					</v-card-text>
				</v-card>
				<v-card class="elevation-0" style="height: 50vh">
					<v-card class="elevation-0">
						<v-card-title> No account yet? </v-card-title>
						<v-card-text>
							Want to start using Azure together with LinkThings
							in order to secure all your passwords?
						</v-card-text>
						<v-card-actions>
							<v-btn
								rounded
								color="orange"
								text
								href="mailto:jay@linkthings.com"
							>
								Contact
							</v-btn>
						</v-card-actions>
					</v-card>
					<v-divider></v-divider>
					<!-- <v-card class="elevation-0">
						<v-card-title> Set-up Azure </v-card-title>
						<v-card-text>
							This UI is build on top of Azure Key Vault, create
							vaults in order to start using this app.
						</v-card-text>
						<v-card-actions>
							<v-btn
								rounded
								color="orange"
								text
								href="https://portal.azure.com"
							>
								Azure
							</v-btn>
						</v-card-actions>
					</v-card> -->
				</v-card>
				<v-card-actions>
					<v-btn
						rounded
						color="primary"
						text
						href="http://www.linkthings.com"
					>
						www.linkthings.com
					</v-btn>

					<v-btn
						rounded
						color="primary"
						text
						href="mailto:info@linkthings.com"
					>
						Support
					</v-btn>
				</v-card-actions>
			</v-col>
			<v-col sm="12" md="8" style="background-color: #10325a">
				<v-container class="fill-height" fluid>
					<v-row align="center" justify="center">
						<v-col sm="8" md="6">
							<v-card>
								<div class="ma-5">
									<v-img
										src="https://www.linkthings.com/assets/themes/site/img/logo.svg"
										width="500px"
									></v-img>
								</div>
								<v-card-actions>
									<v-btn
										color="primary"
										block
										rounded
										@click="loginWithAzureAD"
										>Login with Azure</v-btn
									>
								</v-card-actions>
							</v-card></v-col
						>
					</v-row>
				</v-container>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import msalAuth from "@/utils/msalAuth.js";

export default {
	title: "login",
	data: function () {
		return {
			// account: null,
			// User account object synced with MSAL getAccount()
			msalUser: {},
			// Access token fetched via MSAL for calling Graph API
			msalAccessToken: "",
		};
	},

	computed: {},

	methods: {
		async loginWithAzureAD() {
			try {
				await msalAuth.login();
				this.msalUser = msalAuth.user();
				this.msalAccessToken = await msalAuth.acquireToken([
					"user.read",
				]);
				this.isLoggingIn = true;

				this.$store.commit("user/setIsLoggedIn");
				this.$router.push(this.$route.query.redirect || "/secrets");
			} catch (err) {
				console.log(err);
				this.error = err.toString();
			}
		},
	},
};
</script>
