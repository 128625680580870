const isLoggedIn = Boolean(sessionStorage.getItem("isLoggedIn"))
	? Boolean(sessionStorage.getItem("isLoggedIn"))
	: false;

const state = {
	isLoggedIn: isLoggedIn,
};

const mutations = {
	setIsLoggedIn(state) {
		sessionStorage.setItem("isLoggedIn", true);
		state.isLoggedIn = true;
	},

	setIsLoggedOut(state) {
		sessionStorage.removeItem("isLoggedIn", false);
		state.isLoggedIn = Boolean(false);
	},
};

const actions = {};

const getters = {};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
