<template>
	<v-dialog v-model="show" persistent max-width="600px" @keydown.esc="cancel">
		<v-card>
			<v-card-title class="title">
				<v-icon color="error" class="mr-2">warning</v-icon>
				<slot name="title">Delete Item</slot>
			</v-card-title>
			<v-alert v-if="deleteError" tile type="error" class="mb-0">{{
				deleteError
			}}</v-alert>
			<v-card-text>
				<slot name="content"
					>Are you sure you want to delete this item?</slot
				>
			</v-card-text>
			<v-card-actions class="pb-5 pr-5">
				<v-spacer></v-spacer>
				<v-btn text rounded @click="cancel">Annuleren</v-btn>
				<v-btn
					color="error"
					outlined
					rounded
					:loading="isDeleting"
					@click="confirmed"
					>Verwijderen</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "delete-dialog",
	props: ["dialog", "item", "deleteError"],
	data: function () {
		return {
			show: false,
			isDeleting: false,
		};
	},
	watch: {
		dialog(val) {
			this.show = val;
			this.isDeleting = false;
		},
	},
	methods: {
		cancel() {
			this.show = false;
			this.$emit("update:dialog", false);
		},
		confirmed() {
			this.isDeleting = true;
			// this.show = false;
			this.$emit("confirmed", this.item);
			// this.$emit("update:dialog", false);
		},
	},
};
</script>
