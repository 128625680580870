<template>
	<!-- Edit secret -->
	<v-dialog
		v-model="dialog"
		persistent
		max-width="800px"
		scrollable
		@keydown.esc="closeSecretDialog"
	>
		<v-card>
			<v-card-title class="primary white--text">
				{{ formTitle }}
				<v-spacer></v-spacer>
			</v-card-title>
			<v-alert v-if="error" tile type="error" class="mb-0">{{
				error
			}}</v-alert>

			<v-card-text class="pt-10">
				<v-form v-model="valid" ref="form">
					<v-row>
						<v-col cols="6">
							<v-text-field
								v-model="editedItem.secretName"
								:rules="[
									rules.required,
									rules.maxLength(100),
									rules.secretName,
								]"
								label="Secret Name*"
								outlined
								class="subtitle-2"
								:counter="100"
								:disabled="!isNew"
							></v-text-field>
						</v-col>
						<v-col cols="6">
							<v-autocomplete
								v-model="selectedType"
								:items="types"
								item-text="typeName"
								label="Type*"
								color="primary"
								class="subtitle-2"
								outlined
								:rules="[rules.required, rules.maxLength(255)]"
								:counter="255"
							></v-autocomplete>
						</v-col>
						<v-col cols="6">
							<v-text-field
								v-model="editedItem.username"
								:rules="[rules.maxLength(255)]"
								label="Username"
								outlined
								class="subtitle-2"
								:counter="255"
							></v-text-field>
						</v-col>
						<v-col cols="6">
							<v-text-field
								label="Secret Value*"
								:type="showSecret ? 'text' : 'password'"
								:rules="[rules.required, rules.maxLength(255)]"
								outlined
								v-model="editedItem.secretValue"
								:counter="255"
							>
								<template v-slot:append>
									<v-icon
										color="primary"
										@click="showSecret = !showSecret"
										tabindex="-1"
										>{{
											showSecret
												? "visibility"
												: "visibility_off"
										}}</v-icon
									>
								</template>
							</v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field
								v-model="editedItem.url"
								:rules="[rules.maxLength(255)]"
								label="Url"
								outlined
								class="subtitle-2"
								:counter="255"
							></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-textarea
								v-model="editedItem.notes"
								:rules="[rules.maxLength(255)]"
								label="Notes"
								outlined
								class="subtitle-2"
								:counter="255"
							></v-textarea>
						</v-col>
						<v-col cols="12">
							<v-card>
								<v-card-title>
									Tags
									<v-spacer></v-spacer>
									<v-col cols="12" sm="6">
										<v-text-field
											dense
											v-model="search"
											label="Search"
											single-line
											hide-details
										></v-text-field>
									</v-col>
									<v-spacer></v-spacer>
									<v-btn
										color="primary"
										outlined
										rounded
										small
										@click="addTag"
										:disabled="editedItem.tags.length >= 11"
										>Add tag</v-btn
									>
								</v-card-title>
								<v-data-table
									dense
									:headers="[
										{ text: 'Key', value: 'key' },
										{ text: 'Value', value: 'value' },
										{
											text: 'Actions',
											value: 'action',
											align: 'center',
											sortable: false,
										},
									]"
									:items="editedItem.tags"
									:items-per-page="15"
									item-key="id"
									class="elevation-0"
									:search="search"
									:sort-by="['key']"
									:sort-desc="[false]"
									multi-sort
								>
									<template v-slot:[`item.key`]="{ item }">
										<v-edit-dialog
											:return-value.sync="item.key"
										>
											{{ item.key }}
											<template v-slot:input>
												<v-text-field
													v-model="item.key"
													label="Edit"
													single-line
													:rules="[
														rules.required,
														rules.maxLength(255),
													]"
													:counter="255"
												></v-text-field>
											</template>
										</v-edit-dialog>
									</template>
									<template v-slot:[`item.value`]="{ item }">
										<v-edit-dialog
											:return-value.sync="item.value"
										>
											{{ item.value }}
											<template v-slot:input>
												<v-text-field
													v-model="item.value"
													label="Edit"
													single-line
													:rules="[
														rules.required,
														rules.maxLength(255),
													]"
													:counter="255"
												></v-text-field>
											</template>
										</v-edit-dialog>
									</template>
									<template v-slot:[`item.action`]="{ item }">
										<v-tooltip bottom>
											<template v-slot:activator="{ on }">
												<v-btn
													v-on="on"
													icon
													color="primary"
													small
													@click="deleteTag(item)"
												>
													<v-icon small
														>delete</v-icon
													>
												</v-btn>
											</template>
											<span> Delete Tag </span>
										</v-tooltip>
									</template>
								</v-data-table>
							</v-card>
						</v-col>
					</v-row>
				</v-form>
			</v-card-text>

			<v-card-actions class="pb-5 pr-5">
				<v-spacer></v-spacer>
				<v-btn
					:disabled="isSaving"
					text
					rounded
					@click="closeSecretDialog"
					>Cancel</v-btn
				>
				<v-btn
					outlined
					rounded
					:disabled="!valid"
					:loading="isSaving"
					color="primary"
					@click="saveSecret"
					>Save</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import helper from "@/utils/helper.js";
import msalAuth from "@/utils/msalAuth.js";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export default {
	name: "secret-form",
	props: ["dialog", "object", "selectedVault"],
	data() {
		return {
			valid: false,
			error: "",
			isSaving: false,
			search: "",

			showSecret: false,

			types: [
				{
					typeName: "Password",
				},
				{
					typeName: "Connection String",
				},
				{
					typeName: "API Key",
				},
			],

			selectedType: {},
			tags: [],
			tagKey: null,
			tagValue: null,
			tagsObj: {},
			tagsJson: null,

			editedItem: {},

			rules: {
				required: (v) => !helper.isEmpty(v) || "Dit veld is verplicht",
				minLength: (len) => (v) =>
					(v || "").length >= len ||
					`Invalid character length, required ${len}`,
				maxLength: (len) => (v) =>
					(v || "").length <= len || "Too long",
				secretName: (v) =>
					/^[0-9a-zA-Z-]+$/.test(v) ||
					"Only alphanumeric and hyphens (-) allowed",
			},
		};
	},
	computed: {
		isNew() {
			return this.object ? false : true;
		},
		formTitle() {
			return this.isNew ? "Add secret" : "Edit secret";
		},
	},
	watch: {
		// reset form when dialog open or close
		dialog() {
			this.resetForm();
		},
	},
	created() {},
	methods: {
		resetForm() {
			// reset form state
			this.error = null;
			this.isSaving = false;
			if (this.$refs.form) {
				this.$refs.form.resetValidation();
			}

			// when editing, set form with object
			if (this.dialog && this.object) {
				this.editedItem.secretName = this.object.secretName;
				this.selectedType = this.object.type;
				this.editedItem.username = this.object.username;
				this.editedItem.secretValue = this.object.secretValue;
				this.editedItem.url = this.object.url;
				this.editedItem.notes = this.object.notes;
				this.editedItem.tags = this.object.tags;
			}
			// when new or closing, set form with default
			else {
				this.editedItem.secretName = null;
				this.selectedType = {};
				this.editedItem.username = null;
				this.editedItem.secretValue = null;
				this.editedItem.url = null;
				this.editedItem.notes = null;
				this.editedItem.tags = [];
			}
		},

		async getVaultAccessToken() {
			let scopes = ["https://vault.azure.net/user_impersonation"];
			try {
				let token = await msalAuth.acquireToken(scopes);
				return token;
			} catch (err) {
				console.log(err);
				this.error = err.toString();
			}
		},

		closeSecretDialog() {
			this.$emit("update:dialog", false);
			this.resetForm();
		},

		async saveSecret() {
			let vaultAccessToken = await this.getVaultAccessToken();

			if (!this.$refs.form.validate()) {
				return;
			}

			// set form state
			this.error = null;
			this.isSaving = true;

			if (this.object) {
				axios
					.put(
						`${process.env.VUE_APP_APIURL}azure/vault/secret/create`,
						{
							accessToken: vaultAccessToken,
							vaultUri: this.selectedVault,
							secretName: this.editedItem.secretName,
							secretValue: this.editedItem.secretValue,
							type: this.selectedType,
							username: this.editedItem.username,
							url: this.editedItem.url,
							notes: this.editedItem.notes,
							tags: this.editedItem.tags,
						}
					)
					.then((response) => {
						// console.log(response);
						this.closeSecretDialog();
						this.$emit("done");

						// show snackbar
						const payload = {
							color: "success",
							message: "Updated Secret",
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((err) => {
						console.log(err.response);
						this.error =
							this.error +
							err +
							" | Update Secret | " +
							err.response.data.message;
					})
					.finally(() => {
						this.isSaving = false;
					});
			} else {
				axios
					.post(
						`${process.env.VUE_APP_APIURL}azure/vault/secret/create`,
						{
							accessToken: vaultAccessToken,
							vaultUri: this.selectedVault,
							secretName: this.editedItem.secretName,
							secretValue: this.editedItem.secretValue,
							type: this.selectedType,
							username: this.editedItem.username,
							url: this.editedItem.url,
							notes: this.editedItem.notes,
							tags: this.editedItem.tags,
						}
					)
					.then((response) => {
						// console.log(response);
						this.closeSecretDialog();
						this.$emit("done");

						// show snackbar
						const payload = {
							color: "success",
							message: "Created Secret",
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((err) => {
						console.log(err.response);
						this.error =
							this.error +
							err +
							" | Create Secret | " +
							err.response.data.message;
					})
					.finally(() => {
						this.isSaving = false;
					});
			}
		},

		addTag() {
			var newTag = {
				id: uuidv4(),
				key: "",
				value: "",
			};

			this.editedItem.tags.push(newTag);

			this.$forceUpdate();
		},

		deleteTag(tag) {
			this.editedItem.tags.splice(this.editedItem.tags.indexOf(tag), 1);
			this.$forceUpdate();
		},
	},
};
</script>
